import React, { FC, useEffect, useState } from 'react'
import { Container } from '@components'
import { AboutPage } from '@pages/components'
import { useWindowSize } from '@hooks'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import '../about.scss'
import ArrowRight from '@images/arrow-right.svg'

interface IGuidePorps {}

const Guide: FC<IGuidePorps> = () => {
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const handleScroll = () => setScrollTop(window.scrollY)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScrollUp = () => window.scrollTo(0, 0)

  const scrollThreshold = 250
  const isShowUpButton = scrollTop > scrollThreshold

  return (
    <Container data-relative="true">
      {isShowUpButton && (
        <div className="scroll-up-block" onClick={handleScrollUp}>
          <p>Up</p>
          <ArrowRight className="arrow-scroll" />
        </div>
      )}
      <div className={'guides-links'}>
        <AnchorLink to="/about/guides#buying-guide">Buying Guide</AnchorLink>
        <AnchorLink to="/about/guides#condition-guide">Condition Guide</AnchorLink>
        <AnchorLink to="/about/guides#selling-guide">Selling Guide</AnchorLink>
        <AnchorLink to="/about/guides#shipping-guide">Shipping Gude</AnchorLink>
      </div>
      <>
        <div className={'banner-guide'} id="buying-guide">
          <div className="banner-guide__title"> Buying Guide</div>
          <div className="banner-guide__description">
            Search through the extensive TourDay catalog of golf equipment to find the item you want to buy.
          </div>
        </div>
        <AboutPage.BuyingGuide />
      </>
      <>
        <div className={'banner-guide'} id="condition-guide">
          <div className="banner-guide__title"> Condition Guide </div>
          <div className="banner-guide__description">
            If you are shipping a golf club here are some tips for packing.
          </div>
        </div>
        <AboutPage.ConditionGuide />
      </>
      <>
        <div className={'banner-guide'} id="selling-guide">
          <div className="banner-guide__title"> Selling Guide</div>
          <div className="banner-guide__description">
            Search through the extensive TourDay catalog of golf equipment to find the item you want to sell.
          </div>
        </div>
        <AboutPage.SellingGuide isMobile={isMobileRender} />
      </>
      <>
        <div className={'banner-guide'} id="shipping-guide">
          <div className="banner-guide__title"> Shipping Guide</div>
          <div className="banner-guide__description">
            If you are shipping a golf club here are some tips for packing.
          </div>
        </div>
        <AboutPage.ShippingGuide isMobile={isMobileRender} />
      </>
    </Container>
  )
}

export default Guide
